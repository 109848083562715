@tailwind base;
@tailwind components;
@tailwind utilities;

@layer components {
  .spacings {
    @apply px-[20px] md:px-[35px] lg:px-[60px] xl:px-[100px]  lg:max-w-[1440px] 2xl:max-w-[1600px]  w-full m-[auto];
  }
}

body {
  margin: 0;
  font-family: "Inter";
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: #0b0b0f;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.btn_bg {
  background: linear-gradient(225deg, #1a61ed 14.89%, #11bae3 85.85%);
  /* border: 1px solid; */
  /* border-color: linear-gradient(
    180deg,
    rgba(255, 255, 255, 0.47) 12.55%,
    rgba(255, 255, 255, 0.546875) 42.51%,
    rgba(255, 255, 255, 0.39) 78.66%
  ); */
}

.link {
  text-decoration: none;
}

.gradient_text {
  font-weight: bold;
  background-image: linear-gradient(101.78deg, #e27fe8 26.12%, #22acce 85.4%);
  background-clip: text;
  -webkit-background-clip: text;
  color: transparent;
  font-weight: 900;
  line-height: "62.4px";
}

.bg_hero {
  background-image: url("./assets/icons/circles.svg");
  background-repeat: no-repeat;
  background-size: cover;
  width: 100%;
  height: 100%;
}
